#timelineSection {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin: 48px 0;
}

#timelinePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
}

.timeline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.blueText {
  color: #000bd5;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 8px 0;
}

.cardSection {
    padding-right:8.3%;
    padding-left:8.3%;
    padding-top: 60px;
}

.aviso {
    padding-right:8.3%;
    padding-left:8.3%;
    padding-bottom: 60px;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 32px;
    box-shadow: 0 10px 20px rgba(161, 181, 224, 0.6), 0 0 12px rgba(161, 181, 224, 0.5);
}

.hiden-mobile {
    display: flex;
}

@media (max-width: 596px) {
    .hiden-mobile {
        display: none !important;
    }
}