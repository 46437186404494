.timelineEvent {
  display: flex;
  align-items: flex-start;
  height: 90px;
}

.icon {
  font-size: 56px;
  margin: 0 10px;
}

.lineDone, .lineUpcoming {
  position: relative;
}

.lineDone::after, .lineUpcoming::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
}

.lineDone::after {
  border: 2px solid #212121;
  margin-top: 8px;
}

.lineUpcoming::after {
  border: 2px dashed #a6a6a6;
  margin-top: 8px;
}

[data-tooltip] {
  position: relative;
  cursor: help
}

[data-tooltip]::after {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  content: attr(data-tooltip);
  left: 0;
  top: calc(100% - 10px);
  border-radius: 3px;
  box-shadow: 0 0 5px 2px rgba(100, 100, 100, 0.6);
  background-color: white;
  z-index: 10;
  padding: 8px;
  width: 300px;
  transform: translateY(-20px);
  transition: all 150ms cubic-bezier(.25, .8, .25, 1);
  font-size: small;
}

[data-tooltip]:hover::after {
  opacity: 1;
  transform: translateY(0);
  transition-duration: 300ms;
}