@media (max-width: 767px) {
  .buttonbanner {
    display: none;
  }
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .buttonbanner {
    position: absolute;
    margin-top: 220px !important;
    margin-left: 355px !important;
  }
}
@media all and (min-width: 992px) and (max-width: 1024px) {
  .buttonbanner {
    position: absolute !important;
    margin-top: 220px !important;
    margin-left: 460px !important;
  }
}
@media all and (min-width: 1025px) and (max-width: 1110px) {
  .buttonbanner {
    position: absolute !important;
    margin-top: 220px !important;
    margin-left: 480px !important;
  }
}
@media all and (min-width: 1111px) and (max-width: 1194px) {
  .buttonbanner {
    margin-top: 150px !important;
    margin-left: 830px !important;
  }
}
@media all and (min-width: 1195px) and (max-width: 1199px) {
  .buttonbanner {
    margin-top: 150px !important;
    margin-left: 830px !important;
  }
}
@media all and (min-width: 1199px) and (max-width: 1267px) {
  .buttonbanner {
    margin-top: 150px !important;
    margin-left: 930px !important;
  }
}
@media all and (min-width: 1268px) and (max-width: 1350px) {
  .buttonbanner {
    margin-top: 150px !important;
    margin-left: 960px !important;
  }
}
@media all and (min-width: 1351px) and (max-width: 1400px) {
  .buttonbanner {
    margin-top: 150px !important;
    margin-left: 960px !important;
  }
}
@media all and (min-width: 1401px) and (max-width: 1780px) {
  .buttonbanner {
    position: absolute;
    margin-top: 225px !important;
    margin-left: 1050px !important;
  }
}
@media all and (min-width: 1781px) {
  .buttonbanner {
    /* position: absolute; */
    margin-top: 235px !important;
    margin-left: 1150px !important;
  }
}

.cvp-card.conheca-amparo {
  border-radius: 15px;
  padding: 1rem;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
}

.cvp-card.conheca-amparo .cvp-card-header {
  padding-bottom: 0;
}

.cvp-card.conheca-amparo small {
  font-size: 50% !important;
}

.cvp-card.conheca-amparo .cvp-card-body p {
  text-align: center;
}

.step-item {
  text-align: center;
  padding: 4px;
}

.step-item .step-count-header {
  margin-bottom: 16px;
}

.step-item .step-count-header h5 {
  display: inline;
  border-bottom: 2px solid;
  padding: 0 32px;
  line-height: 0;
}

.cvp-card.card-produto {
  border-top: 5px solid var(--color-brand-primary-light);
  text-align: start;
  padding: 0;
  margin-bottom: 3rem;
}

.cvp-card.card-produto .cvp-card-body {
  min-height: 18rem;
  display: flex;
  align-items: center;
  line-height: 2.1;
  border: 1px solid var(--color-neutral-7);
  border-top: 0;
}

.cvp-card.card-produto .cvp-card-header {
  min-height: 4rem;
  padding: 12px;
  border: 1px solid var(--color-neutral-7);
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.cvp-card.card-produto .cvp-card-footer {
  text-align: center;
}

.cvp-card.card-produto .cvp-card-footer .footer-action {
  border: 1px solid var(--color-neutral-7);
  border-top: transparent;
  min-height: 4rem;
}

.cvp-card.card-produto .cvp-card-footer a.footer-action-link {
  background: transparent;
  display: block;
  color: var(--color-brand-primary-light);
  border-radius: 0;
  min-height: 4rem;
  padding: 1rem;
  width: 100%;
  cursor: pointer;
}

.span {
  font-weight: bold;
  color: var(--color-tangerine-standard);
}

.line {
  text-decoration: underline;
  font-size: 15px;
}

@media all and (min-width: 768px) {
  .conheca-amparo-grid {
    display: grid;
    grid-template-areas: 'title title content content content content content content content content' 'action action content content content content content content content content';
    grid-gap: 0.5rem;
  }
  .conheca-amparo-grid .conheca-amparo-title {
    grid-area: title;
    min-width: 100%;
    margin-right: 24px;
  }
  .conheca-amparo-grid .conheca-amparo-content {
    grid-area: content;
  }
  .conheca-amparo-grid .conheca-amparo-action {
    grid-area: action;
  }
}

@media all and (max-width: 768px) {
  .sorteio-vida .cvp-draw-result-controls {
    padding: 0;
  }
}

.cvp-color-color-brand-text-neutral-2 {
  color: var(--color-brand-text-neutral-2) !important;
}

#bloco-cobertura h6,
#bloco-assistencias h6 {
  font-size: 16px;
  line-height: 19px;
}

@media all and (min-width: 1400px) {
  section.page-block.page-banner {
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 437px;
    padding: 2rem;
  }
}

@media all and (min-width: 800px) and (max-width: 850px) {
  section.page-block.page-banner {
    zoom: 0.8;
  }
}

/* new css */
/* @media all and (min-width: 768px) { 
  .fazer-uma-compra .assistente {
    position: relative;
    margin-top: -130;
  }
} */
#Aproveite-as-vantagens .conteudo {
  min-width: 250px;
  margin-top: -15px;
}
.title-size {
  padding-top: 1rem;
}
.title-size p {
  font-size: 1.4rem;
}
.btn-espaco {
  padding-bottom: 3rem;
}

#principais-beneficios .conteudo {
  color: #ffffff;
  font-size: 0.8rem;
  line-height: 1.1rem;
  margin-top: -80px;
  min-height: 60px;
}
#principais-beneficios .cvp-card {
  padding: 0.5rem;
  text-align: center;
}

#principais-beneficios .col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
}

#faca-seguro-vida .cvp-action-icon {
  margin-top: -47px;
  margin-left: 47px;
  position: absolute;
  border-radius: 10px !important;
}
#faca-seguro-vida .cvp-action.btn .cvp-action-icon.icon-right {
  margin-left: -68px !important;
}

#faca-seguro-vida .cvp-action.btn-rounded {
  border-radius: 6px;
  width: 12rem;
}
#faca-seguro-vida .cvp-action.button {
  margin-top: 40px;
}
.acoes-promocionais {
  justify-content: 'center';
  width: '70%';
}

.box-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  max-width: 310px;
  margin-top: -10px;
}

.box-value {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  max-width: 130px;
  margin-top: -12px!important;
}

.topValue {
  font-size: 0.6em;
  color: white;
  margin-bottom: -6px !important;
}
.subValue {
  font-size: 0.6em;
  color: white;
  margin-top: -12px !important;
  margin-left: 45px!important;
}