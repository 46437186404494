/* CSS */
.margem-base {
  margin-bottom: 70px;
}

/* cor preta */
.basic-color-black {
  color: var(--color-grafite-dark-1);
}

/* cor azul */
.basic-color-blue {
  color: var(--color-brand-primary-medium);
}

/* cor laranja */
.basic-color-tangerine {
  color: var(--color-tangerine-standard);
}

@media (max-width: 767px) {
  .btn-banner {
    display: none;
  }
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .btn-banner {
    position: absolute !important;
    margin-top: 220px !important;
  }
}

@media all and (min-width: 992px) and (max-width: 1110px) {
  .btn-banner {
    position: absolute !important;
    margin-top: 220px !important;
    margin-left: -150px !important;
  }
}

@media all and (min-width: 1111px) and (max-width: 1399px) {
  .btn-banner {
    margin-top: 150px !important;
    margin-left: 200px !important;
  }
}

@media all and (min-width: 1400px) and (max-width: 1780px) {
  .btn-banner {
    position: absolute;
    margin-top: 225px !important;
    margin-left: 305px !important;
  }
}

@media all and (min-width: 1781px) {
  .btn-banner {
    position: absolute;
    margin-top: 235px !important;
    margin-left: 370px !important;
  }
}

/* Apresentação - Acidentes Pessoais */
#apresentacao span:before {
  color: var(--color-tangerine-standard);
  content: "\2022";
  /* bullet  */
}

#apresentacao li {
  list-style-type: none;
  position: relative;
  margin-bottom: 0.5em;
}

#apresentacao li:before {
  content: "•";
  display: inline-block;
  position: absolute;
  left: -1rem;
  color: var(--color-brand-primary-dark);
}

#apresentacao .text-contrast {
  font-weight: 600;
}

#amparo .color-title {
  color: var(--color-brand-primary-dark);
}

/* Pagamento /////////////////////////////////////////////////////////*/

#pagamento-unico .base-btn {
  margin-top: 0.5rem;
  margin-bottom: -2.5rem !important;
  border-radius: 5px !important;
  width: 200px;
}

#pagamento-unico .margem-base {
  margin-bottom: 70px;
}

#pagamento-unico .text-card small {
  font-size: 60% !important;
}

#pagamento-unico .border {
  padding: 1rem;
  border: 1px solid rgb(0 0 0 / 20%) !important;
  border-radius: 4px;
  box-shadow: inset 0px 1px 2px 0px rgb(0 0 0 / 10%), 2px 2px 2px 0px rgb(0 0 0 / 10%);
}

#pagamento-unico .justify-content-center {
  justify-content: flex-start !important;
}

#pagamento-unico .title>p {
  font-size: 1.3rem;
  margin-left: 10px;
}

#pagamento-unico .separador {
  font-size: 2.0rem;
  display: "inline";
  padding-bottom: 100px;
}

#pagamento-unico .valor {
  font-size: 2.3rem;
  margin-left: -120px;
}

#pagamento-unico .plano {
  font-size: 1.3rem;
  margin-top: 1.3rem;
}

/* Assistencia //////////////////////////////////////////////////////////*/

#assistencia-funeral-acidentes-pessoais .toggle-link {
  font-family: var(--font-brand), sans-serif !important;
  font-size: 24px;
  text-decoration: none;
}

#assistencia-funeral-acidentes-pessoais .toggle-link:focus {
  box-shadow: none;
  outline: none;
}

#assistencia-funeral-acidentes-pessoais .toggle-link .toggle-icon {
  transform: scale(1.5, 1);
  margin-top: 4px;
  margin-bottom: 4px;
}

#assistencia-funeral-acidentes-pessoais li {
  list-style-type: none;
  position: relative;
  margin-bottom: 0.5em;
}

#assistencia-funeral-acidentes-pessoais li:before {
  content: "•";
  display: inline-block;
  position: absolute;
  left: -1rem;
  color: var(--color-tangerine-standard);
}

/* Vantagens ///////////////////////////////////////////////////////////////*/

#vantagens dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

/* Bem estar ///////////////////////////////////////////////////////////////*/
#bem-estar {
  margin-top: 7rem;
}

#bem-estar .text-color {
  color: var(--color-tangerine-standard);
}

#bem-estar .borda {
  padding: 1rem;
  border: 1px solid rgb(0 0 0 / 11%) !important;
  border-radius: 4px;
  box-shadow: inset 3px 3px 12px 0px rgb(0 0 0 / 10%), 5px 5px 3px 0px rgb(0 0 0 / 10%);
}

#bem-estar .btn-base {
  border-radius: 5px !important;
  width: 200px;
  position: absolute;
  margin-top: 10rem;
}

#bem-estar .margem-base {
  margin-bottom: 70px;
}

#bem-estar .label {
  position: absolute;
  color: #fff;
  background: var(--color-tangerine-standard);
  line-height: 1.2rem;
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

#bem-estar .card {
  height: 200px !important;
}

#bem-estar .justify-content-center {
  justify-content: flex-start !important;
}

#bem-estar .valor {
  font-size: 2.3rem;
  margin-left: -20px;
  margin-top: -10px;
}

#bem-estar .plano {
  font-size: 1.2rem;
  margin-left: 30px;
  margin-top: 15px;
}

#bem-estar .center {
  display: flex;
  justify-content: center;
}

#bem-estar .btn-center {
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  position: absolute;
  margin-top: -1.3rem;
}

#bem-estar .title {
  font-size: 0.76rem;
  margin-left: -10px;
}

@media all and (max-width: 576px) {
  #bem-estar .title {
    font-size: 0.76rem;
  }

  #bem-estar .label {
    margin-top: 250px;
    margin-left: 130px;
    font-size: 0.76rem;
  }
}

@media all and (min-width: 576px) and (max-width: 768px) {
  #bem-estar .title {
    font-size: 0.88rem;
  }

  #bem-estar .label {
    margin-top: 250px;
    margin-left: 130px;
    font-size: 0.76rem;
  }
}

@media all and (min-width: 768px) and (max-width: 992px) {
  #bem-estar .title {
    font-size: 1.2rem;
  }

  #bem-estar .label {
    width: 130px;
    margin-top: 90px;
    margin-left: 190px;
  }
}

@media all and (min-width: 992px) {
  #bem-estar .title {
    font-size: 1.5rem;
  }

  #bem-estar .label {
    width: 130px;
    margin-top: 90px;
    margin-left: 300px;
  }
}

@media all and (min-width: 992px) and (max-width: 1200px) {
  #bem-estar .title {
    font-size: 1.2rem;
  }

  #bem-estar .label {
    width: 130px;
    margin-top: 90px;
    margin-left: 300px;
  }
}

@media all and (min-width: 1200px) {
  #bem-estar .title {
    font-size: 1.2rem;
  }

  #bem-estar .label {
    width: 130px;
    margin-top: 90px;
    margin-left: 370px;
  }
}

@media all and (min-width: 992px) {
  #bem-estar .marge {
    margin-right: -80px;
  }
}

/* Valores Bem estar ///////////////////////////////////////////////////////////////*/

#valores
{
  padding-top: 4.5rem !important;
}
#valores-bem-estar {
  margin-bottom: 7rem;
}

#valored-bem-estar .color {
  color: var(--color-tangerine-standard);
}

#valores-bem-estar .border {
  border: 1px solid rgb(0 0 0 / 20%) !important;
  border-radius: 4px;
  box-shadow: inset 0px 1px 2px 0px rgb(0 0 0 / 10%), 2px 2px 2px 0px rgb(0 0 0 / 10%);
}

#valores-bem-estar .div-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: inherit;
}

#valores-bem-estar .div-card {
  width: 280px;
  height: 180px;
  margin-top: 30px;
  margin-bottom: 30px;
}

#valores-bem-estar .div-line-a {
  height: 40px;
  margin-left: 20px;
  margin-top: 20px;
}

#valores-bem-estar .div-line-b {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 70px;
  width: 250px;
  height: 50px;
}

#valores-bem-estar .div-line-b--a {
  display: flex;
  align-items: center;
  justify-content: center;
}

#valores-bem-estar .div-line-b--a h1 {
  color: var(--color-tangerine-standard);
}

#valores-bem-estar .div-line-b--c h1 {
  color: var(--color-blue-banner-medium);
}

#valores-bem-estar .div-line-b--b {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-tangerine-standard);
  width: 100px;
  height: 40px;
  border-radius: 50px;
  text-align: center;
  margin: 0px;
  padding: 0px;
}

#valores-bem-estar .div-line-b--b p {
  line-height: 1.2rem;
  color: #fff;
  margin: 5px;
  padding: 5px;
}

#valores-bem-estar .div-line-c {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 30px;
  margin-top: 10px;
}

#valores-bem-estar .div-line-d {
  display: flex;
  justify-content: center;
  align-items: center;
}

#valores-bem-estar .base-btn {
  margin-top: -5px;
  border-radius: 5px !important;
  width: 180px;
}

@media all and (min-width: 992px) and (max-width: 3000px) {
  #valores-bem-estar .div-card {
    width: 350px;
    height: 180px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  #valores-bem-estar .div-line-b {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 100px;
    width: 340px;
    height: 50px;
  }
}

/* Benefícios Amparo ///////////////////////////////////////////////////////////////*/

#beneficios-amparo .cvp-accordion.accordion .card>.card-header .accordion-link-header {
  border: 0;
  outline: none;
  font-size: 1rem !important;
  box-shadow: none;
}

#beneficios-amparo .justify-content-between {
  justify-content: flex-start !important;
}

#beneficios-amparo .accordion-faq.accordion-seguridade-light.accordion .card .card-body {
  background-color: var(--color-neutral-8);
}

#beneficios-amparo .accordion-faq.accordion-seguridade-light.accordion .card>.card-header {
  border-left: 0;
  padding-top: 0rem;
  padding-bottom: 0rem;
  border-bottom: 0px solid var(--color-seguridade-neutral-2) !important;
  margin-bottom: 0;
}

#beneficios-amparo .align-items-center {
  align-items: flex-end !important;
}

#beneficios-amparo .text-label {
  padding-left: 10px;
  /* font-size: 1.0rem; */
  font-weight: 500;
  color: var(--color-brand-primary-medium);
  margin-bottom: 0;
}

#beneficios-amparo .accordion-link-header-icon {
  transform: scale(0.7) !important;
}

#beneficios-amparo .c-primary {
  color: #231f20 !important;
}

/* Benefícios Bem-Estar ///////////////////////////////////////////////////////////////*/

#beneficios-bem-estar span:before {
  color: var(--color-tangerine-standard);
  content: "\2022";
  /* bullet  */
}

#beneficios-bem-estar li {
  list-style-type: none;
  position: relative;
  margin-bottom: 0.5em;
}

#beneficios-bem-estar li:before {
  content: "•";
  display: inline-block;
  position: absolute;
  left: -1rem;
  color: var(--color-tangerine-standard);
}

#beneficios-bem-estar .cvp-accordion.accordion .card>.card-header .accordion-link-header {
  border: 0;
  outline: none;
  font-size: 1rem !important;
  box-shadow: none;
}

#beneficios-bem-estar .justify-content-between {
  justify-content: flex-start !important;
}

#beneficios-bem-estar .accordion-faq.accordion-seguridade-light.accordion .card .card-body {
  background-color: var(--color-neutral-8);
}

#beneficios-bem-estar .accordion-faq.accordion-seguridade-light.accordion .card>.card-header {
  border-left: 0;
  padding-top: 0rem;
  padding-bottom: 0rem;
  border-bottom: 0px solid var(--color-seguridade-neutral-2) !important;
  margin-bottom: 0;
}

#beneficios-bem-estar .align-items-center {
  align-items: flex-end !important;
}

#beneficios-bem-estar .text-label {
  padding-left: 10px;
  color: var(--color-tangerine-standard);
  margin-bottom: 0;
}

#beneficios-bem-estar .accordion-link-header-icon {
  transform: scale(0.7) !important;
}

#beneficios-bem-estar .c-primary {
  color: #231f20 !important;
}

/* Amparo ///////////////////////////////////////////////////////////////*/
@media all and (min-width: 992px) {
  #amparo .marge {
    margin-left: -80px;
  }
}