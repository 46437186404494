.vantagens-prestamista .cvp-card .cvp-card-header {
    min-height: 4rem;
}

#resultado-sorteios-prestamista .cvp-draw-result-controls {
    border: 2px solid var(--color-seguridade-neutral-2);
    border-radius: 5px;
    box-shadow: 0px 8px 16px 4px rgba(19, 72, 178, 0.45);
    padding: 2rem;
}
