.menu-dropdown {
    background: var(--color-neutral-7);
}

.header-seguridade .header-nav {
    border-bottom: 1px solid #fff;
}

header .desktop-submenu .nav-link .cvp-action-icon {
    font-size: 1.5rem;
}

header .desktop-submenu .nav-link.icon-menu-custom .cvp-action-icon {
    width: 20px;
}
.icon-menu{
    width: 25px;
    height: 21.87px;
    margin-top: 10px;
    color: #ef6800;
}
