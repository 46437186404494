.card {
  border-radius: 5px;
  background-color: #fff;
  max-width: 400px;
  margin: 16px;
}

.info {
  display: flex;
  align-items: center;
  padding: 32px;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.icon {
  margin-right: 10px;
}

.button {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  color: #005fc8;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
}

.button:hover {
  border: none;
  background-color: #005fc8;
  color: #fff;
  cursor: pointer;
}

.title {
  font-size: 12px;
  color: #7e828a;
  font-weight: 100;
}

.value {
  font-weight: 600;
  font-size: 18px;
  color: #212121;
}

.product-name-card {
  margin-left: 0.5rem;
  font-size: 18px;
  font-weight: 600;
}

.productBox {
  display: flex;
  align-items: center;
}
