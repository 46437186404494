:root {
  --color--tabela-fundo-1: #1c60ab;
  --color--tabela-fundo-2: #2b76bf;
  --color--tabela-fundo-3: #60a1e1;
  --color--tabela-fundo-4: #9fc5eb;
  --color--tabela-fundo-5: #dcdfe4;
  --color--tabela-fundo-6: #eaedf1;
  --color--tabela-fundo-7: #727782;
}

@media (min-width: 1200px) {
  .informe-banner {
    margin-top: 180px ;
    margin-left: -350px ;
    text-align: left;
  }
  .informe-banner-button {
    margin-top: -80px !important;
    margin-right: 100px !important;
  }
}

@media (min-width: 1400px) {
    .informe-banner {
      margin-top: 170px ;
      margin-left: -400px ;
      text-align: left;
    }
    .informe-banner-button {
      margin-top: -80px !important;
      margin-right: 100px !important;
    }
    
  }

.table.tabela-permanecia-plano thead th {
  background-color: #f36800;
  color: #ffffff;
  border-color: #f36800;
  font-weight: normal;
}

.table.tabela-permanecia-plano > tbody > tr > td {
  border: none !important;
  background: #ffffff;
  vertical-align: middle;
  color: #707070;
}

.table.tabela-permanecia-plano > tbody > tr:nth-child(even) > td,
.table.tabela-permanecia-plano > tbody > tr:nth-child(even) > th {
  background-color: #ffc89e;
}

.table.tabela-permanecia-plano > tbody > tr > td:first-child {
  background: #f36800;
  color: #ffffff;
  border-color: #f36800;
}

.table.tabela-permanecia-plano {
  border: none !important;
}

.table.tabela-fundo-investimento {
  color: var(--color--tabela-fundo-7);
}

.table.tabela-fundo-investimento thead {
  position: sticky;
  top: 120px;
}

.table.tabela-fundo-investimento > thead > tr > th {
  background-color: var(--color--tabela-fundo-1);
  color: var(--color-neutral-8);
  vertical-align: middle;
}

.table.tabela-fundo-investimento tr th.transparent-cell {
  background-color: #ffffff !important;
  border-color: #ffffff;
}

.table.tabela-fundo-investimento > tbody > tr.tipo-fundo-descricao > th {
  background-color: var(--color--tabela-fundo-4);
  color: var(--color--tabela-fundo-1);
}

.table.tabela-fundo-investimento > tbody > tr > td {
  background-color: var(--color--tabela-fundo-6);
  border-color: var(--color-neutral-8);
}

.table.tabela-fundo-investimento > tbody > tr > td.taxa-admin-bg {
  background-color: var(--color--tabela-fundo-5);
}

#interessado .content {
  background-color: #0066b3;
  padding: 20px;
  margin: 50px 0;
}

#interessado .content h3 {
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 30px;
}

#interessado .content p {
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}

@media (min-width: 992px) {
  #interessado .content p {
    text-align: left;
  }
  #interessado .content {
    padding: 50px 100px;
  }
}

#banner-investidor {
  width: 100%;
  height: 350px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}

#banner-investidor h3 {
  font-size: 36px;
  line-height: 60px;
  color: #0e3053;
}

#fundos-composicoes .table-fundos h6 {
  font-size: 16px;
}

#fundos-composicoes .table-fundos table {
  border: 1px solid #0066b3;
  margin-bottom: 30px;
}

#fundos-composicoes .table-fundos table thead {
  border-bottom: 1px solid #0066b3;
}

#fundos-composicoes .table-fundos table thead th {
  border-right: 1px solid #ffffff;
  text-align: center;
  color: #ffffff;
  background-color: #0066b3;
  font-weight: normal;
  padding: 10px;
  font-size: 14px;
}

#fundos-composicoes .table-fundos table thead th:last-child {
  border-right: 1px solid #0066b3;
}

#fundos-composicoes .table-fundos table tbody td {
  border-right: 1px solid #0066b3;
  text-align: center;
  color: #0066b3;
  padding: 10px;
  font-size: 14px;
}

#fundos-composicoes .colapse-fundos .item-colapse {
  width: 100%;
  display: block;
  margin-bottom: 15px;
}

#fundos-composicoes .colapse-fundos .area-rent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  margin-bottom: 15px;
}

#fundos-composicoes .colapse-fundos .area-rent .rent {
  width: 40%;
  display: block;
  text-align: right;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
}

#fundos-composicoes .colapse-fundos .item-colapse button.colapse-head {
  width: 100%;
  border: 0px solid;
  outline: none;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#fundos-composicoes .colapse-fundos .item-colapse button.colapse-head span {
  display: flex;
  align-items: center;
}

#fundos-composicoes .colapse-fundos .item-colapse .colapse-body {
  width: 100%;
  padding: 10px;
}

#fundos-composicoes .colapse-fundos .item-colapse .item-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

#fundos-composicoes .colapse-fundos .item-colapse .item-body .left-item {
  font-weight: bold;
}

.list-alert {
  font-size: 10px;
  font-style: italic;
  text-align: left;
  margin-left: 15px;
}
