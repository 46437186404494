
.login-page {
  min-height: 900px;
  background: linear-gradient(to bottom, #0065b7 45%, #f8f8f8 45%);
  padding-top: 32px;
}

.login .info {
  color: #494949;
  font-size: 16px;
  font-weight: 100;
  margin: 40px 0 50px;
  padding: 0 20px;
  text-align: center;
}

.logo {
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  margin-bottom: 32px;
}

.first-title {
  color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.second-title {
  font-size: 30px;
  margin-top: 1.5rem;
}

.login-container {
  position: relative;
}

.login-card {
  border-top: 5px solid #4187d7;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -47%);
}

.card-section {
  border-radius: 0 0 5px 5px;
  background-color: #f8f8f8;
  min-width: 350px;
  max-width: 500px;
  z-index: 10;
  border: 1px solid #e1e1e1;
  padding: 1.5rem;
}

.screen-description {
  text-align: center;
  margin-bottom: 1rem;
}

.border-login {
  border-right: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  padding: 32px;
}

.login-icon {
  margin-right: 10px;
}

.login-button {
  width: 100%;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
}

.login-button:hover {
  /* border: none; */
  background-color: #005fc8;
  color: #fff;
}

.login-title {
  font-family: "Alright Sans", Arial, sans-serif;
  font-size: 12px;
  color: #7e828a;
  font-weight: 100;
}

.login-value {
  font-weight: 600;
  font-size: 18px;
  color: #212121;
}

.login-product-name-card {
  margin-left: 0.5rem;
  font-size: 18px;
  font-weight: 600;
}

.login-productBox {
  display: flex;
  align-items: center;
}

.login-input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.login-label {
  font-weight: 200;
  font-size: 12px;
  margin-bottom: 0;
}

.login-input-container {
  border: 1px solid #b9b9b9;
  overflow: hidden;
  padding: 0 8px;
  background-color: #fff;
}

.login-input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
}

.login-links {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between;
  margin-top: 16px;
  padding: 10px 10px 0 10px;
  margin-bottom: 0;
}

.login-link {
  color: #005fc8;
  text-decoration: underline;
}

.costumer-service {
  width: 100%;
  text-align: center;
  color: #545f6b;
  margin-top: 1rem;
  padding-bottom: 40px;
}

.costumer-service p {
  opacity: 0.8;
}

.phone-number {
  color: #005fc8 !important;
  opacity: 1;
  text-decoration: none !important;
  /* margin-left: 0.5rem; */
}

.login-description {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: #544f49;
  opacity: 0.9;
}

.login-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.login-text-acompanhar {
  font-size: 24px;
  font-weight: 400;
}

.login-text-login {
  font-size: 18px;
  font-weight: 300;
}

.login-img {
  height: 48px;
  color: #fff;
  margin-bottom: 48px;
}
p {
  margin-bottom: 0.5rem;
}

.footer-login-page {
  padding-top: 5rem;
  background-color: #f8f8f8;
}

.faca-login {
  display: flex;
  align-items: flex-start;
}

#iconLogin {
  /* height: 18px; */
  padding-right: 4px;
}

.login-links button {
  border: none;
  background-color: transparent;
  padding: 0;
  color: #525fcb;
  text-decoration: underline;
  cursor: pointer;
  @media (max-width: 780px) {
padding: 0 20px 10px 0;
  }
}

.faca-login p {
  margin-bottom: 0;
  font-weight: 300;
  opacity: 0.9;
}

.acompanhar-login {
  font-size: 24px;
}

#logo-image-login {
  /* height: 45px; */
  width: 180px;
}

#logo-image-login .st1,
#logo-image-login .st3 {
  fill: #fff !important;
}

#logo-image-login .st0 {
  fill: #f39200 !important;
}
