.box-timeline {
    width: 100% !important;
    display: flex;
    flex-wrap: nowrap !important;
    overflow-x: auto !important;
}

.col-title {
    max-width: 120px !important;
}

.timeline {
    margin-left: -16px;
    list-style-type: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.timestamp {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timeAno {
    color: #494949;
    font-size: 0.7rem;
}

.li.complete {
    width: auto;
}

.li.complete .status {
    border-top: 3px solid#F36800;
    padding: 0px 14px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    position: relative;
    transition: all 200ms ease-in;
}

/* .status {
} */

.li.complete .status .data-bottom {
    margin-top: 15px;
    font-size: 0.7rem;
}

.li.complete .status:before {
    background-color: #F36800;
    border: none;
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 25px;
    position: absolute;
    top: -9px;
    left: 30%;
    transition: all 200ms ease-in;
}

/* .status:before {
} */

.botoes-app-banner {
    width: 100%;
}

.titulo-banner-botao {
    color: #19427e !important;
}

@media (max-width: 992px) {
    .titulo-banner-botao {
        color: #fff !important;
    }
}

@media (max-width: 1024px) {
    .botoes-app-banner {
        width: 40%;
    }
}