.toggle-switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 15px;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #005fc8;
  border-radius: 30px;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 13px;
  height: 13px;
  background: #ffffff;
  border: 1px solid #005fc8;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(21px);
  border-color: #003672;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background: #005fc8;
  border-color: #003672;
}
.toggle-switch-text {
  display: block;
  width: max-content;
  padding-left: 50px;
  margin-top: -5px;
  cursor: pointer;
}
