@media all and (min-width: 768px) {
    .text-banner {
        color: var(--color-brand-text-neutral-1);
    }
}

@media all and (max-width: 768px) {
    .text-banner {
        color: white;
    }
}

.cvp-home-nossos-produtos .cvp-card, .cvp-conheca-nossos-canais .cvp-card {
    padding: 16px 21px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    border-radius: 5px;
}

.cvp-home-nossos-produtos .cvp-card .cvp-card-body p {
    text-align: center;
}

.cvp-home-nossos-produtos .cvp-title-card, .cvp-conheca-nossos-canais .cvp-title-card {
    font-size: 18px;
    line-height: 21px;
    color: #646363;
}

.cvp-home-nossos-produtos .cvp-card-content, .cvp-conheca-nossos-canais .cvp-card-content {
    min-height: 100px;
}

.cvp-conheca-nossos-canais .cvp-card {
    min-height: 230px;
}

.cvp-home-nossos-produtos .cvp-card-content p, .cvp-conheca-nossos-canais .cvp-card-content p {
    font-size: 16px;
    line-height: 140%;
}

.cvp-home-nossos-produtos .cvp-card-label, .cvp-conheca-nossos-canais .cvp-card-label {
    font-size: 14px !important;
    color: black !important;
}

.cvp-home-nossos-produtos .cvp-card-tilte, .cvp-conheca-nossos-canais .cvp-card-tilte {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.cvp-phone {
    font-size: 18px !important;
    color: #000000 !important;
}

.cvp-phone-consultoria {
    line-height: 66px;
}

.cvp-footer-sac {
    margin-top: 30px;
}

.cvp-conheca-nossos-canais .cvp-card .cvp-card-body p {
    text-align: left;
}

.cvp-conheca-nossos-canais .cvp-card .cvp-font-8 {
    font-size: 8px;
    line-height: 140%;
    margin-top: 25px;
}

.cvp-conheca-nossos-canais .cvp-card .cvp-font-12 {
    font-size: 12px;
    line-height: 140%;
    margin-top: 25px;
}

.abrir-ocorrencia-button {
    color: #262f38;
    background: none;
    border: none;
    padding: 0;
    text-decoration: underline;
    font-weight: 600;
    margin-bottom: 5px;
}

.cvp-home-nossos-produtos .cvp-card-tilte .icon-produtos {
    color: var(--color-brand-secondary-medium);
}