.login-footer {
  width: 100%;
  background-image: url("../../../../asserts/images/seguridade-header-background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 16px 2rem;
  @media (max-width: 767px) {
    padding: 16px 2rem;
  }
}

.infos-footer {
  color: #fff;
}

.infos-footer p {
  margin-bottom: 0.3rem !important;
  font-size: 14px;
  @media (max-width: 767px) {
    font-size: 11px;
    width: 310px !important;
  }
}

hr {
  background-color: #c2c3c6;
}

.login-links-footer {
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
}

.login-link-footer {
  color: #fff;
  text-decoration: underline;
  margin-right: 4rem;
  @media (max-width: 767px) {
    width: 310px !important;
  }
}
a:hover {
  text-decoration: none;
  color: #fff;
}

.app-image {
  width: 100%;
  height: 50vh;
  background: url("../../../../asserts/images/image-app.png") no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
}

.border-login {
  border: 1px solid #696868;
  padding: 2rem;
  display: flex;
}

.qr-code {
  height: 15vh;
}

.available {
  height: 40px;
  margin-left: 1rem;
}

.d-flex-modal {
  display: flex;
  justify-content: space-between;
}

.title-banner-modal {
  position: absolute;
  top: 122px;
  left: 5rem;
  text-align: center;
}

.title-banner-modal h4 {
  font-size: 28px !important;
  color: #eb9228;
  font-weight: 600;
}

.title-banner-modal p {
  color: #fff;
  font-weight: 500;
}

.qr-code-text {
  position: absolute;
  bottom: 10px;
  left: 6rem;
  color: #fff;
  font-weight: 500;
}
