.page-whats {
  z-index: 2000;
  height: 100%;
  width: 100%;
  position: fixed;  
  top:0;  
}

.redirect-whatsapp-box{
  height: 100%;
  width: 100%; 
  top: 0; 
  position: fixed;
  background-color: white;
}

.icon-whats{
  width: auto;
  height: auto;
  padding-bottom: 20px; 
  display: block;
  margin-right: auto;
}

.logo-caixa-vida-e-previdencia{  
  position: relative;
  max-width: 100%;
  height: auto;
  margin-top: 1.5rem;
  margin-left: 10%;
  padding-bottom: 27px;
}

.loader-container {
  width: 100%;
  max-width: 375px;
  height: 16px;
  background-color: #D1DBE3;
  margin-top: 0;  
}

.content-whatsapp {
  width: auto;
  height: auto;
  min-height: 50vh; 
  position: absolute;
  justify-content: center;
  margin: auto;
  top: 60%;
  margin-right: -10%; 
  left: 50%;
  transform: translate(-50%, -50%) 
}

.content-whatsapp h1 {
  font-size: 24px;
  color: #494949;
  width: auto;
}

.content-whatsapp h2{
  font-size: 1rem;
  color: #494949;
  width: auto;
}

.loader { 
  width:100%; 
  margin:1 auto;
  border-radius:10px;
  border:4px solid transparent;  
  position:relative;
  height: 16px;
  padding:1px;
}
.loader:before {
  content:'';  
  position:absolute;
  top:-4px; 
  right:-4px; 
  bottom:-4px; 
  left:-4px;
}
.loader .loaderBar { 
  position:absolute;
  top:0;
  right:100%;
  bottom:0;
  left:0;
  background:#5B8E00; 
  width:0;
  animation:borealisBar 1s linear infinite;  
}
.line-whatsapp {
border-top: 1px solid #D1DBE3;
}

@keyframes borealisBar {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:75%;
    width:25%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
}