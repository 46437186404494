:root {
  --color--tabela-fundo-1: #1c60ab;
  --color--tabela-fundo-2: #2b76bf;
  --color--tabela-fundo-3: #60a1e1;
  --color--tabela-fundo-4: #9fc5eb;
  --color--tabela-fundo-5: #dcdfe4;
  --color--tabela-fundo-6: #eaedf1;
  --color--tabela-fundo-7: #727782;
}
@media (min-width: 1200px) {
  .prev-banner-ir {
    display: flex;
    align-items: center;
  }
  .prev-banner-btn {
    margin-left: 100px !important;
  }
}

@media (min-width: 1200px) {
  .previdencia-banner {
    position: absolute;
    margin-top: 130px;
    margin-left: -350px;
    text-align: left;
  }
}

@media (min-width: 1400px) {
  .previdencia-banner {
    position: absolute;
    margin-top: 170px;
    margin-left: -400px;
    text-align: left;
  }
}

.table.tabela-permanecia-plano thead th {
  background-color: #f36800;
  color: #ffffff;
  border-color: #f36800;
  font-weight: normal;
}

.table.tabela-permanecia-plano > tbody > tr > td {
  border: none !important;
  background: #ffffff;
  vertical-align: middle;
  color: #707070;
}

.table.tabela-permanecia-plano > tbody > tr:nth-child(even) > td,
.table.tabela-permanecia-plano > tbody > tr:nth-child(even) > th {
  background-color: #ffc89e;
}

.table.tabela-permanecia-plano > tbody > tr > td:first-child {
  background: #f36800;
  color: #ffffff;
  border-color: #f36800;
}

.table.tabela-permanecia-plano {
  border: none !important;
}

.table.tabela-fundo-investimento {
  color: var(--color--tabela-fundo-7);
}

.table.tabela-fundo-investimento > thead > tr > th {
  background-color: var(--color--tabela-fundo-1);
  color: var(--color-neutral-8);
  vertical-align: middle;
}

.table.tabela-fundo-investimento tr th.transparent-cell {
  background-color: transparent !important;
  border-color: transparent !important;
}

.table.tabela-fundo-investimento > tbody > tr.tipo-fundo-descricao > th {
  background-color: var(--color--tabela-fundo-4);
  color: var(--color--tabela-fundo-1);
}

.table.tabela-fundo-investimento > tbody > tr > td {
  background-color: var(--color--tabela-fundo-6);
  border-color: var(--color-neutral-8);
}

.table.tabela-fundo-investimento > tbody > tr > td.taxa-admin-bg {
  background-color: var(--color--tabela-fundo-5);
}

#interessado .content {
  background-color: #0066b3;
  padding: 20px;
  margin: 50px 0;
}

#interessado .content h3 {
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 30px;
}

#interessado .content p {
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}

@media (min-width: 992px) {
  #interessado .content p {
    text-align: left;
  }
  #interessado .content {
    padding: 50px 100px;
  }
}

#banner-investidor {
  width: 100%;
  height: 350px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}

#banner-investidor h3 {
  font-size: 36px;
  line-height: 60px;
  color: #0e3053;
}

#fundos-composicoes .table-fundos h6 {
  font-size: 16px;
  margin: 0;
  padding: 0;
}

#fundos-composicoes .table-fundos table {
  border: 1px solid #0066b3;
  margin-bottom: 30px;
}

#fundos-composicoes .table-fundos table thead {
  border-bottom: 1px solid #0066b3;
}

#fundos-composicoes .table-fundos table thead th {
  border-right: 1px solid #ffffff;
  text-align: center;
  color: #ffffff;
  background-color: #0066b3;
  font-weight: normal;
  padding: 10px;
  font-size: 14px;
}

#fundos-composicoes .table-fundos table thead th:last-child {
  border-right: 1px solid #0066b3;
}

#fundos-composicoes .table-fundos table tbody td {
  border-right: 1px solid #0066b3;
  text-align: center;
  color: #0066b3;
  padding: 10px;
  font-size: 14px;
}

#fundos-composicoes .colapse-fundos .item-colapse {
  width: 100%;
  display: block;
  margin-bottom: 15px;
}

#fundos-composicoes .colapse-fundos .area-rent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  margin-bottom: 15px;
}

#fundos-composicoes .colapse-fundos .area-rent .rent {
  width: 40%;
  display: block;
  text-align: right;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
}

#fundos-composicoes .colapse-fundos .item-colapse button.colapse-head {
  width: 100%;
  border: 0px solid;
  outline: none;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#fundos-composicoes .colapse-fundos .item-colapse button.colapse-head span {
  display: flex;
  align-items: center;
}

#fundos-composicoes .colapse-fundos .item-colapse .colapse-body {
  width: 100%;
  padding: 10px;
}

#fundos-composicoes .colapse-fundos .item-colapse .item-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

#fundos-composicoes .colapse-fundos .item-colapse .item-body .left-item {
  font-weight: bold;
}

.card-como-comprar {
  border: 1px solid #c3c2c2;
  padding: 0px;
  min-height: 39em;
}

.card-como-comprar .cvp-card-header {
  background-color: var(--main-cuidado-futuro-color-primary);
}

.card-como-comprar .cvp-card-body {
  padding: 10px;
}

#simulador .content-simulador {
  padding: 20px 5px;
}

#simulador .content-simulador h3 {
  margin-bottom: 30px;
}

#simulador .content-simulador label.form-label {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 15px;
}

#simulador .content-simulador input[type="text"],
#simulador .content-simulador input[type="number"] {
  height: 50px;
  max-width: 320px;
  width: 100%;
}

#simulador .content-simulador .info {
  font-size: 12px;
  line-height: 20px;
  color: #646363;
}

#simulador .content-simulador .info.error {
  color: #e83131;
}

#dica .content-dica {
  margin-top: 50px;
}

#dica .content-dica h3 {
  margin-bottom: 20px;
}

#dica .content-dica h3.orange {
  color: #ff7200;
}

h4.title-eco {
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #4e6178;
  margin-top: 50px;
  margin-bottom: 20px;
}

.result {
  background-color: #0066b3;
  padding: 0 20px 20px 20px;
}

.top-res {
  display: flex;
  padding: 30px 0;
  flex-direction: column;
}

.result .item-res {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ffffff;
  padding: 30px 0;
}

.result .item-res:last-child {
  border-right: 0px solid;
  border-bottom: 0px solid;
}

.result .item-res .info-res {
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 20px;
}

.result .item-res .value-res {
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #ffffff;
  font-weight: 900;
}

.btn-plus {
  background-color: transparent;
  border: 0px solid;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  display: flex;
}

.table-result {
  padding: 0px 1px 20px 1px;
  border: 1px solid #0066b3;
}

.table-result table {
  width: 100%;
  margin-bottom: 30px;
}

.table-result table td,
.table-result table th {
  padding: 10px 3px;
  border-right: 2px solid #d1dbe3;
  word-break: break-word;
}

.table-result table thead td,
.table-result table thead th {
  width: 33.3333%;
}

.table-result table td:last-child,
.table-result table th:last-child {
  border-right: 0px solid;
}

.table-result table thead tr th {
  font-size: 16px;
  text-align: center;
  color: #646363;
}

.table-result table thead tr th.blue {
  color: #0066b3;
}

.table-result table thead tr th.white{
  color: #ffffff;
}

.table-result table tbody tr.c-blue td {
  color: #0066b3;
}

.table-result table tbody tr td.big {
  font-size: 16px;
  font-weight: 900;
}

.table-result table tbody tr td {
  font-size: 15px;
  color: #000000;
  text-align: center;
}

.table-result table tbody tr td:first-child {
  text-align: left;
}

.table-result table tbody tr.end-line td {
  border-bottom: 2px solid #d1dbe3;
}

.table-result table tbody tr.back-gray td {
  background: rgba(196, 196, 196, 0.15);
}

.table-result table tbody tr.back-gray-black td {
  background: rgba(196, 196, 196, 0.3);
}

.table-result table tbody tr.end-line-total td {
  border-bottom: 2px solid #d1dbe3;
}

.table-result table tbody tr.total td {
  border: 2px solid #d1dbe3;
}

.table-result table tbody tr.total td:first-child {
  border: 0px solid;
}

@media (min-width: 992px) {
  #simulador .content-simulador {
    padding: 40px;
  }
  .top-res {
    flex-direction: row;
  }
  .result .item-res {
    width: 33.3333%;
    border-right: 1px solid #ffffff;
    border-bottom: 0px solid;
    padding: 0 30px;
  }
  .result {
    background-color: #0066b3;
    padding: 0 0 20px 0;
  }
  .table-result {
    padding: 30px;
  }
  .table-result table thead tr th {
    font-size: 22px;
    line-height: 26px;
  }
  .table-result table tbody tr td.big {
    font-size: 24px;
    line-height: 28px;
  }
  .table-result table tbody tr td {
    font-size: 18px;
    line-height: 21px;
  }
  .table-result table td,
  .table-result table th {
    padding: 20px;
  }
}

@media all and (max-width: 768px) {
  #cp-banner-mobile {
    color: white;
  }
}

.color {
  color: #000000;
}

.info {
  background: var(--color-brand-secondary-light);
  color: var(--color-neutral-8);
  padding: 1em;
  align-items: center;
}

.info > div > p,
.info > div > h3 {
  color: var(--color-neutral-8);
}

.central-atendimento-cancelar {
  margin: 0;
  padding: 0;
  line-height: 1;
}

.sac-cancelar {
  margin: 0;
  padding: 0;
  line-height: 1;
}

iframe:focus {
  outline: none;
}
iframe[seamless] {
  display: block;
}
.video-js {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 0px) and (max-width: 740px) {
  .video-js {
    width: 290px;
    height: 163px;
  }
}

@media screen and (min-width: 741px) and (max-width: 844px) {
  .video-js {
    width: 290px;
    height: 163px;
  }
}

@media screen and (min-width: 992px) {
  .video-js {
    width: 450px;
    height: 253px;
  }
}
/* .vjs-poster {
  border-radius: 10px;
} */
.video-js .vjs-big-play-button {
  background-color: #0066b3 !important;
}

html {
   scroll-behavior: smooth !important;
  }
  .title-cashback{
    display: inline-block;
    color: white;
  }
  .text-cashback{
    color: white;
  }
  .title-assistencias{
    display: inline-block;
    color: white;
  }
  .text-assistencias{
    color: white;
  }
  @media all and (min-width: 768px) {
  .title-cashback{
    display: none!important;
  }
  .text-cashback{
    color: #0e3053;
    margin-top: 140px!important;
  }
  .title-assistencias{
    display: none!important;
  }
  .text-assistencias{
    color: #fff;
    margin-top: 180px!important;
  }
   
}