@font-face {
  font-family: "Alright Sans";
  src: url("./AlrightSansThin/c088677c-ef3e-4a6e-ac49-3a0bdd785689-2.eot");
  src: url("./AlrightSansThin/c088677c-ef3e-4a6e-ac49-3a0bdd785689-2.eot?") format("embedded-opentype"),
    url("./AlrightSansThin/c088677c-ef3e-4a6e-ac49-3a0bdd785689-3.woff") format("woff"), url("./AlrightSansThin/c088677c-ef3e-4a6e-ac49-3a0bdd785689-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Alright Sans";
  src: url("./AlrightSansRegular/3166ad31-2182-4fd1-9d82-7f10d4b7643e-2.eot");
  src: url("./AlrightSansRegular/3166ad31-2182-4fd1-9d82-7f10d4b7643e-2.eot?") format("embedded-opentype"),
    url("./AlrightSansRegular/3166ad31-2182-4fd1-9d82-7f10d4b7643e-3.woff") format("woff"),
    url("./AlrightSansRegular/3166ad31-2182-4fd1-9d82-7f10d4b7643e-1.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Alright Sans";
  src: url("./AlrightSansBold/61992ac3-1582-4b76-9057-2e219552f837-2.eot");
  src: url("./AlrightSansBold/61992ac3-1582-4b76-9057-2e219552f837-2.eot?") format("embedded-opentype"),
    url("./AlrightSansBold/61992ac3-1582-4b76-9057-2e219552f837-3.woff") format("woff"), url("./AlrightSansBold/61992ac3-1582-4b76-9057-2e219552f837-1.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: "Alright Sans";
  src: url("./AlrightSansMedium/5f0232d6-b8f7-4780-babe-eb4163b9484d-2.eot");
  src: url("./AlrightSansMedium/5f0232d6-b8f7-4780-babe-eb4163b9484d-2.eot?") format("embedded-opentype"),
    url("./AlrightSansMedium/5f0232d6-b8f7-4780-babe-eb4163b9484d-3.woff") format("woff"), url("./AlrightSansMedium/5f0232d6-b8f7-4780-babe-eb4163b9484d-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
