
.cvp-card-produto{
  width: 326px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    padding: 0px;
    margin-top: 30px;
    box-shadow: #ccc 0 4px 10px;
    padding-bottom: 2em;

}


.info-card {
  padding: 24px;
  height: 200px;
}
.card-img{
 width: 100%;
 height: 250px;
}
.btn-card{
  display: flex;
  justify-content: center;
}

.title-cashback{
  display: inline-block;
  color: white;
}
.text-cashback{
  color: white;
}

.title-assistencias{
  display: inline-block;
  color: white;
}
.text-assistencias{
  color: white;
}

@media all and (min-width: 768px) {

.title-cashback{
  display: none!important;
}
.text-cashback{
  color: #0e3053;
  margin-top: 115px!important;
}

.title-assistencias{
  display: none!important;
}
.text-assistencias{
  color: #0e3053;
  margin-top: 115px!important;
  width: 540px;
}

}