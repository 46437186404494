#o-que-esta-incluso .toggle-link {
  font-family: var(--font-brand), sans-serif !important;
  font-size: 24px;
  text-decoration: none;
}

#o-que-esta-incluso .toggle-link:focus {
  box-shadow: none;
}

#o-que-esta-incluso .toggle-link .toggle-icon {
  transform: scale(1.5, 1);
  margin-top: 4px;
  margin-bottom: 4px;
}

#pague-uma-vez-ao-ano {
  padding: 0;
}

#pague-uma-vez-ao-ano .container {
  padding-top: 24px;
  padding-bottom: 40px;
}

.amparo-central-atendimento-cancelar {
  margin: 0%;
  padding: 0%;
  line-height: 1;
}

.amparo-sac-cancelar {
  margin: 0%;
  padding: 0%;
  line-height: 1;
}
