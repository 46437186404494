.box-image {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: left;
  align-items: start;
  height: 70px;
  border-bottom: 1px solid var(--color-seguridade-neutral-2) !important;
  border-radius: 5px;
  margin: 1rem 1rem 0 1rem;
  z-index: 0;
}

.drop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px dashed rgba(0, 0, 0, 0.09);
  padding: 20px;
  min-height: 50px;
}

.drop:hover {
  border: 1px dashed rgba(0, 0, 0, 0.09);
  background-color: #f7f9fa;
}

.icone {
  min-width: 100px;
  flex: 1;
}

.text {
  color: #e0e0e0;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  flex: 11;
  -webkit-touch-callout: none; /* iPhone OS, Safari */
  -webkit-user-select: none; /* Chrome, Safari 3 */
  -khtml-user-select: none; /* Safari 2 */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none; /* Possível implementação no futuro */
}
@media (min-width: 600px) {
  .text {
    font-size: 28px;
  }
}

.icon {
  height: 25px;
  width: 25px;
  font-weight: 600;
  padding: 0 8px;
  border-radius: 3px;
  position: absolute;
  bottom: 4px;
  right: 4px;
  border: 1px solid #e0e0e0;
}

.img {
  max-width: 50px;
  max-height: 100px;
}
.svgContainer {
  display: flex;
  width: 100px;
  padding-left: 10px;
  padding-right: 10px;
}

.svgIcon {
  width: 100%;
  height: 100%;
}

.tipo {
  display: flex;
}

.infos {
  display: flex;
  flex-direction: column;
  justify-content: start;
  /* background-color: red; */
  padding: 10px;
}
.lixeiraIcon {
  width: 20px;
  height: 20px;
  fill: red;
  /* Adicione outras propriedades de estilo conforme necessário */
}
.fileContainer {
  height: 300px; /* Defina a altura desejada para a seção de exibição dos arquivos */
  overflow: auto; /* Habilita a barra de rolagem quando necessário */
}
