
#faca-seguro-vida-senior h1 {
  color: var(--color-brand-primary-medium) !important;
}
#vantagens-vida-senior img{
  width: auto !important;
  height: auto !important;
}
#apresentacao-vida-senior img{
  max-width: 200px !important;
  max-height: auto !important; 
  padding: 15px;
}
#banner-seguro-vida-senior .btn-senior{
border-radius: 50px !important;
background-color: #0cccec;
border: none;

}
#banner-seguro-vida-senior .btn-senior:hover{
  background-color:  #0cafcc;
  border: none;
}