@font-face {
    font-family: 'Futura Std';
    src: url('FuturaStd-Medium.eot');
    src: url('FuturaStd-Medium.eot?#iefix') format('embedded-opentype'), url('FuturaStd-Medium.woff2') format('woff2'), url('FuturaStd-Medium.woff') format('woff'), url('FuturaStd-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Std Bold';
    src: url('FuturaStd-Bold.eot');
    src: url('FuturaStd-Bold.eot?#iefix') format('embedded-opentype'), url('FuturaStd-Bold.woff2') format('woff2'), url('FuturaStd-Bold.woff') format('woff'), url('FuturaStd-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}