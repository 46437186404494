.info {
    background: var(--color-brand-secondary-light);
    color: var(--color-neutral-8);
    padding: 1em;
    align-items: center;
}

.info>div>p, .info>div>h3 {
    color: var(--color-neutral-8);
}

.ajuda {
    background: var(--color-brand-primary-light) !important;
    padding: 2em;
}