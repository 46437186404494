@font-face {
  font-family: 'Caixa Std Bold';
  src: url('./CAIXAStd-Bold.woff') format('woff'),
    url('./CAIXAStd-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: 'Caixa Std Bold Italic';
  src: url('./CAIXAStd-BoldItalic.woff') format('woff'),
    url('./CAIXAStd-BoldItalic.woff2') format('woff2');
  font-style: italic;
  font-weight: bold;
}
@font-face {
  font-family: 'Caixa Std Book';
  src: url('./CAIXAStd-Book.woff') format('woff'),
    url('./CAIXAStd-Book.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'Caixa Std Book Italic';
  src: url('./CAIXAStd-BookItalic.woff') format('woff'),
    url('./CAIXAStd-BookItalic.woff2') format('woff2');
  font-style: italic;
  font-weight: normal;
}
@font-face {
  font-family: 'Caixa Std Bold';
  src: url('./CAIXAStd-ExtraBold.woff') format('woff'),
    url('./CAIXAStd-ExtraBold.woff2') format('woff2');
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: 'Caixa Std Bold Italic';
  src: url('./CAIXAStd-ExtraBoldItalic.woff') format('woff'),
    url('./CAIXAStd-ExtraBoldItalic.woff2') format('woff2');
  font-style: Italic;
  font-weight: bold;
}
@font-face {
  font-family: 'Caixa Std Italic';
  src: url('./CAIXAStd-Italic.woff') format('woff'),
    url('./CAIXAStd-Italic.woff2') format('woff2');
  font-style: italic;
  font-weight: 200;
}
@font-face {
  font-family: 'Caixa Std Lighter';
  src: url('./CAIXAStd-Light.woff') format('woff'),
    url('./CAIXAStd-Light.woff2') format('woff2');
  font-style: normal;
  font-weight: lighter;
}
@font-face {
  font-family: 'Caixa Std Lighter Italic';
  src: url('./CAIXAStd-LightItalic.woff') format('woff'),
    url('./CAIXAStd-LightItalic.woff2') format('woff2');
  font-style: italic;
  font-weight: lighter;
}
@font-face {
  font-family: 'Caixa Std';
  src: url('./CAIXAStd-Regular.woff') format('woff'),
    url('./CAIXAStd-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: 'Caixa Std Bold';
  src: url('./CAIXAStd-SemiBold.woff') format('woff'),
    url('./CAIXAStd-SemiBold.woff2') format('woff2');
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: 'Caixa Std Bold Italic';
  src: url('./CAIXAStd-SemiBoldItalic.woff') format('woff'),
    url('./CAIXAStd-SemiBoldItalic.woff2') format('woff2');
  font-style: italic;
  font-weight: bold;
}
